import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Hang Power Cleans 3×12`}</p>
    <p>{`Back Extensions 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP:`}</p>
    <p>{`10-KB Hang Squat Cleans, Right Arm (53/35)`}</p>
    <p>{`10-KB Hang Squat Cleans, Left Arm`}</p>
    <p>{`15-KB Facing Burpees`}</p>
    <p>{`15-K2E’s`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      